import settingRoutes from "@/settings-config/router/settingRoutes";
import staffDirectoryRoutes from "@/staff-directory/router/staffDirectoryRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "@/common/ui-kit/PageNotFound.vue";
import {
  decodeRouteToken,
  fetchToken,
  getToken,
  getUserid,
  isLoggedIn,
  setToken
} from "../services/auth";
import { encodeUrlPath } from "@/helpers/utils";
import { useStore } from "@/store";

const route: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      (window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`),
    // (window.location.href = `${env.VUE_APP_SSO}/login`),
    meta: { auth: false }
  },
  {
    path: "/myglobalcomponentsarehere",
    name: "ShowGlobal",
    component: () =>
      import(
        /* webpackChunkName: "global" */ "@/common/ui-kit/GlobalComponents.vue"
      )
  },
  {
    path: "/legacy",
    name: "legacy",
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },

  {
    path: "/",
    alias: "/home",
    name: "home",
    meta: { auth: true },
    // redirect: { name: "all-employees" },
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "@/staff-directory/views/AllEmployees.vue"
      )
  },
  {
    path: "/add-employee",
    name: "add-employee",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "@/staff-directory/views/AddEmployee.vue"
      ),
    meta: { auth: true }
  },
  {
    path: "/edit-profile/:id",
    name: "edit-profile",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "@/staff-directory/views/EditProfile.vue"
      ),
    meta: { auth: true }
  },
  {
    path: "/learnmore",
    name: "Learn More",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ "@/staff-directory/components/LearnMore.vue"
      )
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: "/request-loan",
    name: "request-loan",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`)
  },

  {
    path: "/create-access",
    name: "create-access",
    meta: { auth: true },
    component: () => import("@/settings-config/views/CreateAccessLevel.vue")
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;

export const routes = route.concat(settingRoutes).concat(staffDirectoryRoutes);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});
router.beforeEach(async (to, _from, next) => {
  if (getToken()) return next();
  const response = await fetchToken();
  if (response.app_token) {
    const appToken = response.app_token;
    const decodedToken = decodeRouteToken(appToken as string);
    const loginHint = response.login_hint as string;
    const path = to.path;
    setToken(appToken as string);
    useStore()?.setUser(decodedToken);
    useStore()?.setId(decodedToken.user_id);
    useStore()?.setName(decodedToken.first_name ?? "");
    useStore()?.setProfilePicture(decodedToken.profile_image);
    useStore()?.setLoginHint(loginHint);
    next(path);
  } else if (to.meta.auth && !isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${env.VUE_APP_SSO}/v2/login?redirect-to=${redirect}`; // to change this to new dashboard after ever service complete new implementation
  } else {
    next();
  }
});

export default router;
