<template>
  <div
    class="
      absolute
      right-0
      top-12
      BG0
      w-fit
      pl-3
      pr-12
      py-2.5
      shadow-md
      z-10
      rounded
      N800
      P20
    "
  >
    <a class="flex gap-2 mb-4 cursor-pointer" @click.prevent="viewEmployee()">
      <img src="@/assets/profile.svg" class="self-center" alt="profile" />
      <p>My profile</p>
    </a>
    <a
      class="flex gap-2"
      href="javascript:void(0);"
      @click.prevent="userLogout()"
    >
      <img src="@/assets/logout.svg" alt="logout" />
      <p>Logout</p>
    </a>
  </div>
</template>
<script setup lang="ts">
import { useAccessStore } from "@/store/store_access";
import { encodeUrlPath } from "@/helpers/utils";
import router from "@/router";
import { logout } from "@/services/auth";
import { ref } from "vue";
import { useStore } from "@/store/index";

const store = useStore();
const user_id = ref(store.id);
const Access = useAccessStore();
const env = process.env;
const userLogout = () => {
  Access.$reset();

  logout().then(() => {
    window.location.href = `${env.VUE_APP_SSO}/logout?login-hint=${store?.loginHint}&account=${store.user.email}&redirect-url=${env.VUE_APP_BASE}/login`;
  });
};

const viewEmployee = () => {
  let encryptedId = encodeUrlPath(user_id.value?.toString());
  router
    .push({ name: "view-employee", params: { id: encryptedId } })
    .then(() => {
      location.reload();
    });
};
</script>
