import { UserType } from "@/types/AuthTypes";
import {
  EmployeeTypesType,
  IdPrefixType,
  LocationType,
  EmployeeOrganisationType,
  OfficeType,
  EmployeeOrganizations
} from "@/staff-directory/types/types";
import { defineStore } from "pinia";

export const useStore = defineStore({
  id: "login_data",
  state: () => ({
    email: "",
    id: 0,
    manager: 0,
    adviser: [] as Array<number>,
    url: "",
    userName: "",
    allUsers: [] as Array<object>,
    user: {} as UserType,
    profilePicture: "",
    empInfoUsers: [] as Array<object>,
    permissions: {},
    currentStep: null,
    totalStep: null,
    onboardingPending: false as boolean,
    userInfo: {} as UserType,
    cdcAdvisor: 0 as string | number,
    loanuser: 0,
    organisation: {} as EmployeeOrganisationType,
    showAll: true,
    loginHint: ""
  }),
  getters: {},
  actions: {
    setLoanUser(data: number) {
      this.loanuser = data;
    },
    setEmail(email: string) {
      this.email = email;
    },
    setId(id: number) {
      this.id = id;
    },
    setManager(id: number) {
      this.manager = id;
    },
    setAdviser(ids: Array<number>) {
      this.adviser = ids;
    },
    setURL(url: string) {
      this.url = url;
    },
    setName(name: string) {
      this.userName = name;
    },
    setAllUsers(data: Array<object>) {
      this.allUsers = data;
    },
    setUser(data: UserType) {
      this.user = data;
    },
    setProfilePicture(url: string) {
      this.profilePicture = url;
    },
    setEmpInfoUsers(data: Array<object>) {
      this.empInfoUsers = data;
    },
    setPermission(data: object) {
      this.permissions = data;
    },
    setOnboardingStatus(data: boolean) {
      this.onboardingPending = data;
    },
    setUserInfo(data: UserType) {
      this.userInfo = data;
    },
    setCdcAdvisor(data: string | number) {
      this.cdcAdvisor = data;
    },
    setOrganisation(data: EmployeeOrganisationType) {
      this.organisation = data;
    },
    setLoginHint(data: string) {
      this.loginHint = data;
    }
  },
  persist: true
});

export const useConfig = defineStore({
  id: "config",
  state: () => ({
    departments: [] as Array<object>,
    positions: [] as Array<object>,
    employeeTypes: [] as EmployeeTypesType[],
    idPrefixes: [] as IdPrefixType[],
    locations: [] as LocationType[],
    organisations: [] as EmployeeOrganizations[],
    offices: [] as OfficeType[]
  }),
  getters: {},
  actions: {
    setDepartments(data: Array<object>) {
      this.departments = data;
    },
    setPositions(data: Array<object>) {
      this.positions = data;
    },
    setEmployeeTypes(data: EmployeeTypesType[]) {
      this.employeeTypes = data;
    },
    setIdPrefixes(data: IdPrefixType[]) {
      this.idPrefixes = data;
    },
    setLocations(data: LocationType[]) {
      this.locations = data;
    },
    setOffices(data: OfficeType[]) {
      this.offices = data;
    },
    setOrganisations(data: EmployeeOrganizations[]) {
      this.organisations = data;
    }
  },
  persist: true
});

export const userInfo = defineStore({
  id: "user_info",
  state: () => ({
    userInfo: {} as object,
    empInfo: [] as Array<object>,
    empStatus: [] as Array<object>
  }),
  getters: {},
  actions: {
    setUser(data: object) {
      this.userInfo = data;
    },

    setEmpInfo(data: Array<object>) {
      this.empInfo = data;
    },

    setEmpStatus(data: Array<object>) {
      this.empStatus = data;
    }
  },
  persist: false
});

export const useMangersIdStore = defineStore({
  id: "manger",
  state: () => ({
    manager: "",
    cdc_advisor: "",
    name: ""
  })
});
