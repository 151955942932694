export default [
  {
    path: "/all-employees",
    name: "all-employees",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/AllEmployees.vue"),
    meta: { auth: true }
  },
  {
    path: "/add-employee",
    name: "add-employee",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/AddEmployee.vue"),
    meta: { auth: true }
  },
  {
    path: "/create-employee-onboarding",
    name: "create-employee-onboarding",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../views/EmployeeOnboarding.vue"
      ),
    meta: { auth: true }
  },
  {
    path: "/welcome",
    name: "employee-onboarding-welcome",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../views/EmployeeOnboardingPreview.vue"
      ),

    meta: { auth: true }
  },
  {
    path: "/onboarding-form",
    name: "onboarding-form",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../components/UserEmployeeInformationForm.vue"
      ),

    meta: { auth: true }
  },
  {
    path: "/introduction-form",
    name: "introduction-form",
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../components/UserIntroductionForm.vue"
      ),

    meta: { auth: true }
  },
  {
    path: "/view-employee/:id/personal",
    name: "view-employee",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/ViewEmployee.vue"),
    meta: { auth: true },
    children: [
      {
        path: "payslip/:payslipId",
        name: "PayslipDetails",
        component: () =>
          import("../views/employeePayslips/EmployeePayslipDetails.vue")
      },
      {
        path: "",
        name: "Personal Information",
        component: () =>
          import("../views/personalInformation/PersonalInformation.vue")
      },
      {
        path: "notes",
        name: "Notes",
        component: () =>
          import("@/staff-directory/views/employeePerformance/hrNotes.vue")
      },

      {
        path: "performance",
        name: "Performance Information",
        component: () =>
          import(
            "@/staff-directory/views/employeePerformance/AssessmentComponent.vue"
          )
      },
      {
        path: "bonus",
        name: "Bonus",
        component: () =>
          import(
            /* webpackChunkName: "overview-analytics" */ "../views/employeeBonus/BenefitComponent.vue"
          )
      },

      {
        path: "work",
        name: "Work Information",
        component: () => import("../views/workInformation/WorkInformation.vue")
      },
      {
        path: "loans",
        name: "Loans",
        component: () => import("../views/employeeLoan/EmployeeLoans.vue")
      },
      {
        path: "payslips",
        name: "Payslips",
        component: () =>
          import("../views/employeePayslips/EmployeePayslips.vue"),
        children: []
      },
      {
        path: "projects",
        name: "Projects",

        component: () =>
          import("../views/employeeProjects/EmployeeProjectView.vue")
      },
      {
        path: "projects-details/:projectId",
        name: "EmployeesProjectsDetails",
        component: () =>
          import("../views/employeeProjects/EmployeeProjectDetails.vue")
      }
    ]
  },
  {
    path: "/edit-profile/:id",
    name: "edit-profile",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/EditProfile.vue"),
    meta: { auth: true }
  },
  {
    path: "/performance-improvement-plan/:id",
    name: "performancePlan",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "@/staff-directory/views/employeePerformance/components/pipComponents/PipStepperComponent.vue"
      )
  },
  {
    path: "/overview-analytics/:id",
    name: "OverviewAnalytics",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "overview-analytics" */ "@/staff-directory/views/employeePerformance/AssessmentOverviewAnalytics.vue"
      )
  }
];
