<template>
  <button
    type="submit"
    :title="title"
    :disabled="disabled"
    @click="submitData()"
    class="
      button
      text-white
      btn-shadow
      P200
      bg-[#DD5928]
      hover:bg-[#B5461D]
      rounded-lg
      py-2
      focus:outline-none focus:ring-2 focus:ring-[#EB9C7F]
      disabled:opacity-25 disabled:cursor-not-allowed
    "
  >
    <div>
      <div class="pl-2" role="status">
        {{ text }}
        <loadingStatus v-if="loading" />
        <span v-if="loading" class="sr-only">Loading...</span>
        &nbsp;
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import loadingStatus from "@/assets/template-icons/loading-status.vue";

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Fill all required fields to activate",
  },
  text: {
    type: String,
    default: "Save",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<(e: "submit") => void>();

const submitData = () => emit("submit");
</script>

<style scoped>
button {
  vertical-align: middle;
}
</style>
