<template>
  <RouterLink :to="`${item?.route}`" class="">
    <div
      class="
        flex
        items-center
        gap-4
        rounded-lg
        cursor-pointer
        my-4
        py-3
        px-4
        hover:bg-[#fff0ea]
        w-full
      "
      :style="{ justifyContent: !store.showAll ? 'center' : 'start' }"
      :class="$route.path === item?.route ? 'bg-[#fff0ea] justify-start' : ''"
    >
      <TooltipComponent
        :text="item?.label"
        :position="'right'"
        v-if="!store.showAll"
      >
        <slot></slot>
      </TooltipComponent>

      <div v-else>
        <slot></slot>
      </div>
      <span
        class="
          text-base
          hide_view
          sm:hidden
          md:hidden
          lg:block
          xl:block
          whitespace-nowrap
        "
        :class="$route.path === item?.route ? 'O400' : ''"
        v-if="store.showAll"
        >{{ item?.label }}</span
      >
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { useStore } from "@/store";
import TooltipComponent from "@/common/ui-kit/Tooltip/TooltipComponent.vue";

const store = useStore();
defineProps({
  item: {
    type: Object as PropType<{ label: string; route: string }>
  }
});
</script>
